body {
  margin: 0;
  font-family: 'Libre Franklin', -apple-system, 'Helvetica Neue', sans-serif;
  font-family: 'Libre Franklin-Medium', -apple-system, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Libre Franklin";
  src: local("Libre Franklin"),
    url("./fonts/Inconsolata/static/Inconsolata/Inconsolata-Light.ttf") format("truetype");
  font-weight: normal;
  
}

@font-face {
  font-family: "Libre Franklin-Medium";
  src: local("Libre Franklin-Medium"),
    url("./fonts/Inconsolata/static/Inconsolata/Inconsolata-Medium.ttf") format("truetype");
  font-weight: normal;

}

@font-face {
  font-family: "Libre Franklin-SemiBold";
  src: local("Libre Franklin-SemiBold"),
    url("./fonts/Inconsolata/static/Inconsolata/Inconsolata-Medium.ttf") format("truetype");
  font-weight: normal;

}



html, body {
  height: 100%;
  width: 90%;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}
body {
  /* background-image: url("https://www.transparenttextures.com/patterns/absurdity.png") !important; */
  background-color:#000000 !important;
  overflow: auto;
  display: block;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  font-family: PoiretOne-Regular;
  min-height: 10vh;
  content: "";
  width: 100%;
  position: absolute;
  z-index: 1;
  text-overflow: clip;
}
.a-card {
  margin: 10px;
  justify-content: left;
  align-items: left;
}
#portfolio .portfolio-item .portfolio-caption {
    max-width: 400px;
    margin: 0 auto;
    padding: 25px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0);
}
.a-card-image {
  display: inline-table;
  border-radius: 10px;
  width: 340px;
  height: auto;
  box-shadow: 0px 0px 0px 0px #ccc;
  opacity: 0.7;
  transition: 1s ease-in-out;
  justify-content: left;
  align-items: left;
}

.a-card-image:hover {
    opacity: 1.0;
    width: 463px;
}
/* On screens that are 992px or less, set font-size to 14px */
@media screen and (max-width: 992px) {
 .a-card-image {
    width: 300px,
  }
}
/* On screens that are 992px or less, set font-size to 14px */
@media screen and (max-width: 992px) {
 .a-card-image:hover {
    width: 340px,
  }
}
/* On screens that are 600px or less, set font-size to 12px */
@media screen and (max-width: 300px) {
  .a-card-image {
    width: 200px,
  }
}
@media screen and (max-width: 300px) {
  .a-card-image:hover{
    width: 230px,
  }
}
.navbar-light .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(226, 226, 226, 0.545);
}
.a-card-title {
  font-size: 20px;
  margin: 0px;
  font-weight:lighter
}
.a-card-sub-title {
  font-size: 16px;
  margin: 0px;
  font-weight:lighter
}
