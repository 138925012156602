.container {
  width: 60%;
  margin: auto; 
}
.gallery {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 0.4rem; 
}
.gallery__img {
  width: 100%;
  height: 100%;
  display: block; 
}
.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 4.9;
  /** Alternative Syntax **/
  /* grid-column: 1 / span 2;  */
  /* grid-row: 1 / span 2; */
}
.gallery__item--2 {
  grid-column-start: 1;
  grid-column-end: 4.9;
  grid-row-start: 4.9;
  grid-row-end: 9;
  /** Alternative Syntax **/
  /* grid-column: 3 / span 2;  */
  /* grid-row: 1 / span 2; */
}
.gallery__item--3 {
  grid-column-start: 4.9;
  grid-column-end: 9;
  grid-row-start: 4.9;
  grid-row-end: 9;
  /** Alternative Syntax **/
  /* grid-column: 5 / span 4;
  grid-row: 1 / span 5; */
}
.gallery__item--4 {
  grid-column-start: 1;
  grid-column-end: 4.5;
  grid-row-start: 9.5;
  grid-row-end: 15;
  /** Alternative Syntax **/
  /* grid-column: 1 / span 4;  */
  /* grid-row: 3 / span 3; */
}
.gallery__item--5 {
  grid-column-start: 4.9;
  grid-column-end: 9;
  grid-row-start: 4.5;
  grid-row-end: 15;
  /** Alternative Syntax **/
  /* grid-column: 1 / span 4; */
  /* grid-row: 6 / span 3; */
}
.gallery__item--6 {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 9.5;
  grid-row-end: 21;
  /** Alternative Syntax **/
  /* grid-column: 5 / span 4; */
  /* grid-row: 6 / span 3; */
}
.gallery__item--7 {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 9.5;
  grid-row-end: 30;
} 
.gallery__item--8 {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 9.5;
  grid-row-end: 38;
} 